
import React from 'react'

import { getStruct, saveObj, getItem, trocaloja } from '../../store/api.js'
import { AppSettings } from '../../config/app-settings.js'

import InputTextMask    from '../_atoms/InputTextMask'
import InputImage   from '../_molecules/InputImage'
import DropDown     from '../_atoms/DropDown'
import InputText    from '../_atoms/InputText'
import TextArea     from '../_atoms/TextArea'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'

export default class FormLoja extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            user: user,
           
            model:this.props.model,
            id:this.props.id,
            arrcampos:[],
            horarios:[],
            diasDaSemana:[
                { dia:'Domingo', ativo: false, hi: "00:00", hf: '00:00', update: false},
                { dia:'Segunda', ativo: false, hi: "00:00", hf: '00:00', update: false},
                { dia:'Terça',   ativo: false, hi: "00:00", hf: '00:00', update: false},
                { dia:'Quarta',  ativo: false, hi: "00:00", hf: '00:00', update: false},
                { dia:'Quinta',  ativo: false, hi: "00:00", hf: '00:00', update: false},
                { dia:'Sexta',   ativo: false, hi: "00:00", hf: '00:00', update: false},
                { dia:'Sabado',  ativo: false, hi: "00:00", hf: '00:00', update: false}
            ],
            data:{
              items:{},
              relations:{}
            },
            imagem: user && user.loja.imagem && user.loja.imagem.imagem ? user.loja.imagem.imagem : user && user.imagem_perfil ? user.imagem_perfil.imagem : null
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
        this.setDelivery = this.setDelivery.bind(this)
        this.setPedido = this.setPedido.bind(this)
        this.setEstoque = this.setEstoque.bind(this)
        this.setTaxaServico = this.setTaxaServico.bind(this)
        this.setHorario = this.setHorario.bind(this)
        this.setHorarioAtivo = this.setHorarioAtivo.bind(this)
        this.handleHorario = this.handleHorario.bind(this)
      
    }

    async componentDidMount() {
        const struct = await getStruct(this.state.model)
        this.setState({ arrcampos: struct.data })

        const item = await getItem(this.state.model, this.state.id)
        const diasDaSemana = this.state.diasDaSemana
        const data = [1,2,4,8,16,32,64]

        if (item.data && item.data != '') {
            if(item.data.horarios.length > 0) {
                const h = []
                item.data.horarios.forEach( (item) => {
                    h.push({id_ec_loja:item.id_ec_loja, dias:item.dias, horario_inicial: item.horario_inicial, horario_final: item.horario_final, id:item.id}) 
                    diasDaSemana[data.indexOf(item.dias)].id_ec_loja = item.id_ec_loja 
                    diasDaSemana[data.indexOf(item.dias)].ativo = item.b_ativo
                    diasDaSemana[data.indexOf(item.dias)].hi = item.horario_inicial 
                    diasDaSemana[data.indexOf(item.dias)].hf = item.horario_final
                    diasDaSemana[data.indexOf(item.dias)].id = item.id
                })

                this.setState({ diasDaSemana:diasDaSemana })
            }

            this.setState({ data: {items: item.data, relations: {Variacao: item.data.variacao} }})
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, this.state.model)

          this.setHorario()
          this.state.horarios.forEach((item) => {
            saveObj({items:item}, 'Horarios')
          })

          await trocaloja({brand: this.state.user.brand })

          window.location.href = "/"

          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {

          console.log(e)
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    setDelivery(e){
        this.setModelData('b_delivery', e.target.checked)
    }

    setTaxaServico(e) {
        this.setModelData('b_taxa_servico', e.target.checked)
    }

    setPedido(e){
        this.setModelData('b_pedidos', e.target.checked)
    }

    setEstoque(e){
        this.setModelData('b_estoque', e.target.checked)
    }

    handleHorario(element){
        const id = element.getAttribute('idx')
        const name = element.name
        const dias = this.state.diasDaSemana
        dias[id][name] = element.value
        dias[id].update = true

        this.setState({diasDaSemana: dias})
    }

    setHorarioAtivo(id){
        const dias = this.state.diasDaSemana
        dias[id].ativo = !dias[id].ativo
        dias[id].update = true
        
        this.setState({diasDaSemana: dias})
    }

    setHorario(){
        const handleHorario = []
        const diasDaSemana = this.state.diasDaSemana
        const data = [1,2,4,8,16,32,64]

        diasDaSemana.forEach( async (item, idx) => {
            const d = {id_ec_loja:this.state.user.loja.id, dias:data[idx], horario_inicial: item.hi, horario_final: item.hf, b_ativo:item.ativo}

            if (item.id){
                d.id = item.id
            }

            if (item.update) handleHorario.push(d)
        })
    
        this.setState({horarios: handleHorario})
    }
    
    render() {
        const faturamento = this.state.arrcampos.filter((element) => element.nome === "faturamento")
        const funcionario = this.state.arrcampos.filter((element) => element.nome === "funcionarios")
        const operacao = this.state.arrcampos.filter((element) => element.nome === "operacao")
        const tempo = this.state.arrcampos.filter((element) => element.nome === "tempo")
        const tipo = this.state.arrcampos.filter((element) => element.nome === "tipo")
        const atual = this.state.data.items
        const dias = this.state.diasDaSemana
       
        return (

            <form onSubmit={this.handleSubmit}>
                <div className='row'>

                    <div className="col-md-6">

                        <div className="col-md-4" style={{float:"left"}}>

                            <InputImage 
                                nome="id_gb_imagem"
                                setFomrData={this.setModelData}
                                dadosIniciais={this.state.imagem ? this.state.imagem : ""}
                                required={true}
                                round={true}
                            />
                        
                        </div>

                        <div className="col-md-8"  style={{float:"right"}}>

                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="nome"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.nome : ""}
                                />
                                <Label nome="nome" label="Nome Fantasia"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <TextArea
                                    nome="descricao"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.descricao : ""}
                                />
                                <Label nome="descricao" label="Descricao da loja" textArea={true}/>
                            </div>

                        </div>

                    </div>

                    <div className="col-md-6">

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="cnpj"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.cnpj : ""}
                            />
                            <Label nome="cnpj" label="CPNJ Empresa"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="instagram"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.instagram : ""}
                            />
                            <Label nome="instagram" label="url instagram"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="twitter"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.twitter : ""}
                            />
                            <Label nome="twitter" label="url twitter"/>
                        </div>

                    </div>
                    
                    <hr className="bg-gray-500" />

                    <div className="col-md-6" style={{padding:" 0 20px"}}>

                        <div className="card border-0 bg-light mb-15px">
                            <div className="card-body">
                                <h4 className="card-title">Pedidos na mesa QR Code</h4>
                                <p className="card-text">Ative a opção na plataforma para receber pedidos diretamente na mesa via QR code. Se desativada, o cliente ainda acessa o QR code, mas apenas para visualizar o cardápio, sem a função de enviar pedidos à cozinha.</p>
                            
                                <div className="form-check form-switch mb-15px">
                                    <input className="form-check-input form-check-input-pos" checked={atual ? atual.b_pedidos : ""} name="b_ativo" type="checkbox" onChange={this.setPedido}  id="ativo_cardapio" />
                                    <label className="form-check-label" htmlFor="ativo_cardapio">Receber pedidos na mesa</label>
                                </div>
                            </div>
                        </div>

                        <div className="card border-0 bg-light mb-15px">
                            <div className="card-body">
                                <h4 className="card-title">Plataforma de delivery</h4>
                                <p className="card-text">Ative a opção na plataforma para receber pedidos diretamente do seu Delivery. Se desativada, o cliente ainda acessa, mas apenas para visualizar o cardápio, sem a função de enviar pedidos.</p>
                            
                                <div className="form-check form-switch mb-15px">
                                    <input className="form-check-input form-check-input-pos" checked={atual ? atual.b_delivery : ""} name="b_ativo" type="checkbox" onChange={this.setDelivery}  id="ativo_cardapio" />
                                    <label className="form-check-label" htmlFor="ativo_cardapio">Receber pedidos no Delivery</label>
                                </div>

                                <p className="card-text">Whatsapp que o cliente irá se comunicar quando estiver fazendo pedidos no delivery:</p>

                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="whatsapp"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={atual ? atual.whatsapp : ""}
                                    />
                                    <Label nome="whatsapp" label="Whatsapp do meu negócio:"/>
                                </div>

                            </div>
                        </div>

                        <div className="card border-0 bg-light mb-15px">
                            <div className="card-body">
                                <h4 className="card-title">Taxa de serviço</h4>
                                <p className="card-text">Personalize a taxa de serviço como preferir e escolha se ela estará disponível no PDV.</p>
                            
                                <div className="form-check form-switch mb-15px">
                                    <input className="form-check-input form-check-input-pos" checked={atual ? atual.b_taxa_servico : ""} name="b_taxa_servico" type="checkbox" onChange={this.setTaxaServico}  id="taxa_servico" />
                                    <label className="form-check-label" htmlFor="taxa_servico">Incluir taxa de serviço no Ponto de Venda (PDV)</label>
                                </div>

                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="taixa_servico"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={atual ? atual.taixa_servico : ""}
                                    />
                                    <Label nome="taixa_servico" label="Taxa de serviço"/>
                                </div>
                            </div>
                        </div>

                        <div className="card border-0 bg-light mb-15px">
                            <div className="card-body">
                                <h4 className="card-title">Estoque de produtos</h4>
                                <p className="card-text">Ative ou desative o estoque simplificado conforme sua necessidade. Com essa configuração, as vendas dos produtos não serão limitadas por estoque ou disponibilidade.</p>
                            
                                <div className="form-check form-switch mb-15px">
                                    <input className="form-check-input form-check-input-pos" checked={atual ? atual.b_estoque : ""} name="b_estoque" type="checkbox" onChange={this.setEstoque}  id="ativo_cardapio" />
                                    <label className="form-check-label" htmlFor="ativo_cardapio">Ativar controle de estoque</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6" style={{padding:" 10px 30px"}}>

                        <div className="mb-15px">
                            <h4 className="card-title">Informações sobre minha operação</h4>
                            <p className="card-text">Essas informações nos permitirão criar um plano personalizado, alinhado às suas necessidades específicas. Agradecemos pela colaboração e estamos à disposição para garantir a melhor experiência possível para a sua empresa.</p>
                        </div>  

                        <div className="form-floating mb-15px">
                            <DropDown 
                                nome="funcionarios"
                                setFomrData={this.setModelData}
                                options={funcionario.length > 0 ? funcionario[0].values : null}
                                dadosIniciais={atual ? atual.funcionarios : false}
                                label="Selecione a quantidade de funcionarios"
                            />
                        </div>

                        <div className="form-floating mb-15px">
                            <DropDown 
                                nome="faturamento"
                                setFomrData={this.setModelData}
                                options={faturamento.length > 0 ? faturamento[0].values : null}
                                dadosIniciais={atual ? atual.faturamento : false}
                                label="Selecione o faturamento mensal"
                            />
                        </div>

                        <div className="form-floating mb-15px">
                            <DropDown 
                                nome="tempo"
                                setFomrData={this.setModelData}
                                options={tempo.length > 0 ? tempo[0].values : null}
                                dadosIniciais={atual ? atual.tempo : false}
                                label="Selecione o tempo de atuação"
                            />
                        </div>

                        <div className="form-floating mb-15px">
                            <DropDown 
                                nome="operacao"
                                setFomrData={this.setModelData}
                                options={operacao.length > 0 ? operacao[0].values : null}
                                dadosIniciais={atual ? atual.operacao : false}
                                label="Selecione o tipo de operação"
                            />
                        </div>

                        <div className="form-floating mb-15px">
                            <DropDown 
                                nome="tipo"
                                setFomrData={this.setModelData}
                                options={tipo.length > 0 ? tipo[0].values : null}
                                dadosIniciais={atual ? atual.tipo : false}
                                label="Selecione o tipo de restaurante"
                            />
                        </div>

                        <div className="form-floating mb-15px">
                            <h4 className="card-title">Horário de funcionamento do meu negócio</h4>
                            <p className="card-text">Configure os períodos de atendimento conforme sua conveniência, e isso se refletirá diretamente no serviço de delivery. Tenha total controle sobre os horários de operação, garantindo uma experiência adaptada às suas necessidades e às dos seus clientes.</p>
                            
                            {dias.map( (item, idx) => 
                                <div key={"item_"+item.dia} className='row'>
                                    <div className='col-md-4'>
                                        <div className="form-check form-switch mb-15px">
                                            <input className="form-check-input form-check-input-pos" checked={item.ativo} name={item.dia} type="checkbox" onChange={() => this.setHorarioAtivo(idx)}  id={"dia_da_semana_"+idx} />
                                            <label className="form-check-label" htmlFor={"dia_da_semana_"+idx}>{item.dia}</label>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="form-floating mb-15px">
                                            <InputTextMask
                                                nome="hi"
                                                setFomrData={this.handleHorario}
                                                idx={idx}
                                                mask={"99:99"}
                                                dadosIniciais={item ? item.hi : ""}
                                                returnObject={true}
                                            />
                                            <Label nome="hi" label="Horario Inicio"/>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="form-floating mb-15px">
                                            <InputTextMask
                                                    nome="hf"
                                                    idx={idx}
                                                    setFomrData={this.handleHorario}
                                                    mask={"99:99"}
                                                    dadosIniciais={item ? item.hf : ""}
                                                    returnObject={true}
                                                />
                                            <Label nome="hf" label="Horario Fim"/>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
    
        )
    }
}