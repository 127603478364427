
import React from 'react'

import { saveObj, getItem } from '../../store/api.js'
import { AppSettings } from '../../config/app-settings.js'

import InputText    from '../_atoms/InputText.jsx'
import Label        from '../_atoms/Label.jsx'
import Helper       from '../../config/helper.js'

export default class FormRedesSociais extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            user: user,
           
            model:"Loja",
            id:this.props.id,
           
            data:{
              items:{},
              relations:{}
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
    }

    async componentDidMount() {
     
        const item = await getItem(this.state.model, this.state.id)
       

        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data }})
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, this.state.model)
        
          window.location.href = "/"

          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {

          console.log(e)
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }
    
    render() {
        const atual = this.state.data.items
       
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>

                    <div className="col-md-6">

                        <div className="card border-0 bg-light mb-15px">
                            <div className="card-body">
                                <h4 className="card-title">Integrar com FACEBOOK</h4>
                                <p className="card-text">O Facebook Pixel é uma ferramenta analítica fornecida pelo Facebook para ajudar a medir o sucesso de campanhas patrocinadas. Ele funciona através da inserção de um código em cada página do seu site.</p>
                            
                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="fb_pixel"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={atual ? atual.fb_pixel : ""}
                                    />
                                    <Label nome="fb_pixel" label="Identificador do Pixel"/>
                                </div>

                                <p className="card-text">Os eventos de PageView, Visualização de conteúdos, Pesquisa, Adição ao carrinho, Pagamento iniciado e Compra são emitidos automaticamente pela GRUB. O evento de PageView não é emitido via APl. (recomendamos uso de Analitycs).</p>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">

                        <div className="card border-0 bg-light mb-15px">
                            <div className="card-body">
                                <h4 className="card-title">Integrar com GOOGLE TAG MANAGER</h4>
                                <p className="card-text">Insira o ID do seu contêiner no Google Tag Manager (GTM) e conecte seu cardápio à GRUB para automatizar e otimizar as vendas do seu delivery.</p>
                            
                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="gtm"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={atual ? atual.gtm : ""}
                                    />
                                    <Label nome="gtm" label="ID do CONTÊINER"/>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}