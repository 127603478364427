import React from 'react'
import ReactHtmlParser from "react-html-parser"

import { AppSettings } from './../../config/app-settings.js'
import { doLogin } from './../../store/api.js'
import Helper from './../../config/helper.js'

export default class LoginV3 extends React.Component {
	static contextType = AppSettings

	constructor(props) {
		super(props)

		this.handleSubmit = this.handleSubmit.bind(this);
		this.ran = Math.floor(Math.random() * 36)

		this.state = {
			email: '',
			password: '',
		};
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentClass('p-0');
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentClass('');
	}

	async handleSubmit(event) {
		event.preventDefault();

		const result = await doLogin({ email: this.state.email, password: this.state.password })

		if (result.data.token && result.data.ativo && result.data.admin){
			await this.context.saveUserData(result.data)
			this.props.info.history.push('/dashboard')
		} else {
			Helper.addNotification('danger', 'Erro', 'Por favor verifique seus dados, não foi possivel efetuar o login.', 'top-right')
		}
	}

	render() {

		const texts = [
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o GRUB, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o GRUB e veja os resultados acontecerem.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'O GRUB utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o GRUB, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o GRUB e veja os resultados acontecerem.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'O GRUB utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o GRUB, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o GRUB e veja os resultados acontecerem.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'O GRUB utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o GRUB, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o GRUB e veja os resultados acontecerem.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'O GRUB utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o GRUB, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o GRUB e veja os resultados acontecerem.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'O GRUB utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o GRUB, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o GRUB e veja os resultados acontecerem.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'O GRUB utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>GRUB</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			}
		]
		return (
			<div className="login login-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{backgroundImage: 'url(https://grubicons.s3.amazonaws.com/bg' + this.ran +'.jpg)'}}></div>
					<div className="news-caption">
						<h4 className="caption-title">{ReactHtmlParser(texts[this.ran].title)}</h4>
						<p>
							{texts[this.ran].text}
						</p>
					</div>
				</div>
				<div className="login-container">
					<div className="login-header mb-30px">
						<div className="brand">
							<div className="d-flex align-items-center">
								<img alt="" src={`https://grubicons.s3.amazonaws.com/logo.svg`} style={{height:'67px'}} />
							</div>
						</div> 
						
					</div>
					<div className="login-content">
						<form onSubmit={this.handleSubmit} className="fs-13px">
							<div className="form-floating mb-15px">
								<input type="text" className="form-control h-45px fs-13px" placeholder="Email Address" name="email" required onChange={this.onChange}/>
								<label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email Address</label>
							</div>
							<div className="form-floating mb-15px">
								<input type="password" className="form-control h-45px fs-13px" placeholder="Password" name="password" required onChange={this.onChange}/>
								<label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Password</label>
							</div>
							<div className="mb-15px">
								<button type="submit" className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">Acessar a plataforma</button>
							</div>
						</form>

						
						{/* <div className="mb-15px">
							<Link to="/registro" className="btn btn-primary d-block">Criar uma conta</Link>
						</div> */}
						<hr />
						{/* <p className="text-center text-grey-darker">
							&copy; Lock N Load All Right Reserved 2021
						</p> */}
					</div>
				</div>
			</div>
		)
	}
}
