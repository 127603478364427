import React from 'react'
import { Link } from 'react-router-dom'

import { Modal, ModalBody } from 'reactstrap'

import { getList, deleteObj, saveObj } from '../../store/api.js'

import Paginacao from '../paginacao/Paginacao.jsx'
import Helper    from '../../config/helper.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"
import lottieAlerta from "../../assets/cuidado-alerta.json"

class ListaVoucher extends React.Component {

	constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			brand: user.brand ? user.brand : "",
			loja: user.loja,
			model:null,
			lista:[],
			modalAlert: false,
			OBJID: null
		}

		this.fetchData = this.fetchData.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.deleteItem = this.deleteItem.bind(this)
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})

			this.setState({model: this.props.model})
		}
	}

	componentDidMount() {
		this.fetchData()
	}

	handlerPagination(e){
		getList(this.state.model, {page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	async deleteItem(e){
		e.preventDefault()

		await deleteObj('User/'+this.state.OBJID)
		this.setState({ modalAlert: false, OBJID: null })
		getList(this.props.model).then(result => {
			this.setState({ lista: result })
		})
	}

	async handleChangeProduct(data){
		try {
			await saveObj({items:data}, this.state.model)
			Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas no produto salvas com sucesso.', 'top-right')

			getList(this.state.model).then(result => {
				this.setState({ lista: result })
			})

		  } catch (e) {
			Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
		  }
	}

	showDate(valor){
		const date = new Date(valor)

		return date.getDate() + "/" + ( date.getMonth() + 1 ) + "/" + date.getFullYear()
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}

	render() {
		const lista = this.state.lista
		return (
			<div>
				{lista  && lista.length > 0 ? (
					<div className="card border-0">
						
						<div className="tab-content p-3">
							
							<div className="tab-pane fade show active" id="allTab">
								
								<div className="table-responsive mb-3">
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<thead>
											<tr>
												<th>Email</th>
												<th>Gerencial</th>
												<th>PDV</th>
											</tr>
										</thead>
										<tbody>
											{lista.map((item) => 
												<tr key={"item_"+item.id}>
													<td> 
														<i className="fa-solid fa-tag" style={{background: "#0043ff", color: "white", padding: "10px", borderRadius: "7px"}}></i> 
														<strong style={{color: "#0043ff", textTransform: "uppercase", marginLeft: "10px"}}>{item.email}</strong>
													</td>
													
													<td>
														<div className="form-check form-switch col-6">
															<input className="form-check-input" name="b_admin" type="checkbox" checked={item.b_admin}  onChange={() => {this.handleChangeProduct({b_admin: !item['b_admin'], id: item.id})}} id="ativo_cardapio" />
															
														</div>
													</td>
													<td>
														<div className="form-check form-switch col-6">
															<input className="form-check-input" name="b_pdv" type="checkbox" checked={item.b_pdv}  onChange={() => {this.handleChangeProduct({b_pdv: !item['b_pdv'], id: item.id})}} id="ativo_cardapio" />
															
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)
				:
				( 
					<div className="card border-0" style={{padding:"15px"}}>

						<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
						<h1 style={{textAlign:"center"}}>Nenhum Usuário cadastrado por aqui! Cadastre um agora. </h1>
						<p  style={{textAlign:"center"}}></p>
					</div>
				)
				}

				<Modal isOpen={this.state.modalAlert} toggle={() => this.setState({ modalAlert: false })}>
					<ModalBody>
						<div className='text-center'>
							<Lottie animationData={lottieAlerta} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Atenção: esta ação é crítica e irreversível.</h3>
								<p>O voucher será excluido.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalAlert: false })}>Cancelar</button>
							<button className="btn btn-danger" onClick={(e) => this.deleteItem(e)}>Sim, desejo eliminar este item</button>
						</div>
					</ModalBody>
				</Modal>	
			</div>
		)
	}
}

export default ListaVoucher;