import React from 'react'

import { saveObj, getItem } from '../../store/api.js'

import InputText    from '../_atoms/InputText.jsx'
import Label        from '../_atoms/Label.jsx'
import Helper       from '../../config/helper.js'

export default class FormVoucher extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            model:this.props.model,
            brand:user ? user.brand : "",
            id:this.props.id,
            data:{
              items:{b_admin:false, b_pdv:false},
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
    }

    async componentDidMount() {
        const item = await getItem(this.state.model, this.state.id)

        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data, relations: {Variacao: item.data.variacao} }})
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          const data = this.state.data

          if (data.items.senha === data.items.confrma_senha) {

            await saveObj(this.state.data, "criauser")
            Helper.addNotification('success', 'Sucesso', 'Registro salvo com sucesso', 'top-right')
            window.history.back()
          } else {
            Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'codigo ou valor não preenchidos.', 'top-right')
          }
        } catch (e) {
            console.log(e)
            Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data

            if (name === 'b_admin' || name === 'b_pdv') { 
                data.items[name] = value ? 1 : 0
            } else {
                data.items[name] = value
            }

            this.setState({ data })
        }
    }


    render() {
        const atual = this.state.data.items
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <div className="col-md-6">
                    
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="email"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.email : ""}
                            />
                            <Label nome="email" label="Usuário"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="senha"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.codigo : ""}
                            />
                            <Label nome="senha" label="Senha"/>
                        </div>
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="confrma_senha"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.confrma_senha : ""}
                            />
                            <Label nome="confrma_senha" label="Confrma Senha"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h5>Utilização</h5>

                        <div className='row mb-30px'  style={{padding:'0 10px'}}>
                            <div className="form-check form-switch col-12 mb-2">
                                <input className="form-check-input" name="b_admin" type="radio" checked={this.state.data.items['b_admin']} onChange={() => {this.setModelData("b_admin", !this.state.data.items['b_admin'])}} id="b_admin" />
                                <label className="form-check-label" htmlFor="utilizacao">Gerencial</label>
                            </div>

                            <div className="form-check form-switch col-12 mb-2">
                                <input className="form-check-input" name="b_pdv" type="radio" checked={this.state.data.items['b_pdv']} onChange={() => {this.setModelData("b_pdv", !this.state.data.items['b_pdv'])}} id="b_pdv" />
                                <label className="form-check-label" htmlFor="utilizacao">PDV</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}