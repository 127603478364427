import React from 'react'

import Lista 		from '../../components/lista/Lista'
import TopPage 		from '../../components/_organisms/TopPage'
import ListaMesas   from '../../components/_templates/ListaMesas'
import ListaPedidos from '../../components/_templates/ListaPedidos'
import ListaProduto   from '../../components/_templates/ListaProduto'
import ListaMotoboy   from '../../components/_templates/ListaMotoboy'
import ListaOpcional  from '../../components/_templates/ListaOpcional'
import ListaCLientes  from '../../components/_templates/ListaCliente'
import ListaCategoria from '../../components/_templates/ListaCategorias'
import ListaVoucher from '../../components/_templates/ListaVoucher'
import ListaUsers from '../../components/_templates/ListaUsers'

class ListarModulo extends React.Component {
	constructor(props) {
		super(props);

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			user: user,
			brand:user.brand ? user.brand : "",
			model:this.props.info.match.params.model
			
		}
	}

	componentDidUpdate(){
		const m = this.props.info.match.params.model
		if (this.state.model !== m){
			this.setState({model:m})
		}
	}

	render() {
		let topModel = this.state.model === 'Comanda' ? 'Pedidos' : this.state.model === 'Categoria' ? 'Categoria' : this.state.model
		topModel = this.state.model === 'Motoboys' ? 'Entregadores' : topModel
		topModel = topModel === 'Cliente' ? 'Clientes' : topModel

		return (
			<div>
				<TopPage model={topModel} lista={true}>
               		 {this.state.model === "Mesa" && (
                        <p className="lead">
                            Organize suas mesas e comandas com eficiência. Controle total do atendimento, otimize processos e melhore a experiência dos clientes.
                        </p>
                    )}

					{this.state.model === "Comanda" && (
                        <p className="lead">
							Aqui você terá o controle total: acompanhe, em um único lugar, os pedidos do Ponto de Venda e das plataformas de delivery. Mais controle, mais praticidade.
						</p>
                    )}

					{this.state.model === "Produto" && (
                        <p className="lead">
							Cadastre e gerencie seus produtos. Mantenha seu menu sempre atualizado, garantindo uma oferta variada e atrativa para os clientes.
						</p>
                    )}

					{this.state.model === "Categoria" && (
                        <p className="lead">
							Organize seus produtos em categorias. Facilite a navegação e a escolha dos clientes, tornando a experiência de compra mais intuitiva.
						</p>
                    )}

					{this.state.model === "Motoboys" && (
                        <p className="lead">
							Cadastre seus próprios entregadores de delivery. Controle total na escolha, gestão e personalização da sua equipe de entregas.
						</p>
                    )}

					{this.state.model === "Opcional" && (
                        <p className="lead">
							Adicione itens e valores extras para os opcionais dos seus produtos, coloque preços avulsos e aumente sua receita com esta funcionalidade.
						</p>
                    )}

					{this.state.model === "Cliente" && (
                        <p className="lead">
							Aqui você pode visualizar informações detalhadas de cada cliente, como o número total de pedidos realizados, a quantidade de produtos adquiridos e a data da última compra. Esta funcionalidade facilita o acompanhamento do comportamento de compra e o gerenciamento do relacionamento com os clientes.
						</p>
                    )}

					{this.state.model === "Voucher" && (
                        <p className="lead">
							Crie e gerencie vouchers promocionais. Atraia novos clientes e fidelize os atuais com ofertas especiais e descontos exclusivos.
						</p>
                    )}
				</TopPage>

				{this.state.model == "Usuarios" && (
					<ListaUsers
						model = "User"
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}
				
				{this.state.model == "Comanda" && (
					<ListaPedidos
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}

				{this.state.model == "Mesa" && (
					<ListaMesas
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}

				{this.state.model == "Motoboys" && (
					<ListaMotoboy
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}

				{this.state.model == "Opcional" && (
					<ListaOpcional
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}

				{this.state.model == "Produto" && (
					<ListaProduto
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}

				{this.state.model == "Categoria" && (
					<ListaCategoria
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}

				{this.state.model == "Cliente" && (
					<ListaCLientes
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}

				{this.state.model == "Voucher" && (
					<ListaVoucher
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}

				{this.state.model != "Usuarios" && this.state.model != "Categoria" && this.state.model != "Motoboys" && this.state.model != "Comanda" && this.state.model != "Mesa" && this.state.model != "Produto" && this.state.model != "Opcional" && this.state.model != "Cliente" && this.state.model != "Voucher" &&(	
					<Lista
						model = {this.state.model}
						history = {this.props.info.history}
						paginacao = {true}
					/>
				)}
			</div>
		)
	}
}

export default ListarModulo;
