import React from 'react'

import TopPage from '../../components/_organisms/TopPage'
import FormRedesSociais from '../../components/_templates/FormRedesSociais'

export default class EditarIntegracao extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            user: user,
            model:this.props.info.match.params.model,
            tabActive: "tab-1"
        }

        this.handleTabs = this.handleTabs.bind(this)
    }

    handleTabs(e){
        e.preventDefault()

        this.setState({tabActive: e.target.dataset.tab})
    }

    render() {
        return (
            <div>
                <TopPage titulo="Integrações" new={true}></TopPage>

                <p className="lead">
                    Simplifique a gestão do seu negócio gastronômico integrando nossa plataforma às ferramentas que você já utiliza. Conecte sistemas de pagamento, delivery, ERP e muito mais, garantindo eficiência, sincronização de dados e um fluxo de trabalho otimizado.
                </p>

                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a href="#tab-1" data-tab="tab-1" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-1" ? "nav-link active" : "nav-link"}>
                            REDES SOCIAIS
                        </a>
                    </li>
                </ul>

                <div className="tab-content panel p-5 rounded-0 rounded-bottom">
                    <div className={this.state.tabActive == "tab-1" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-1">
                        <FormRedesSociais model={this.state.model} id={this.state.user.loja.id} />
                    </div>
                </div>
            </div>
        )
    }
}