import React from 'react'
import { Link } from 'react-router-dom'
import Helper       from '../../config/helper'

import QRCode from "react-qr-code"
import toImg from 'react-svg-to-image'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import Paginacao from '../paginacao/Paginacao.jsx'
import InputText    from '../_atoms/InputText'
import Label        from '../_atoms/Label'

import { getList, deleteObj, getStructList, saveObj } from '../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"
import lottieAlerta from "../../assets/cuidado-alerta.json"

class ListaMesas extends React.Component {

	constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			brand: user.brand ? user.brand : "",
			model:null,
			lista:[],
			struct:[],
			mesa:null,
			modalQrCode: false,
			modalAlert: false,
			OBJID: null,
			modalNew:false,
			modalOcupada:null,
			b_comanda: 0,
			quantidade:0,
			inicio:0,
			filtro:"mesas",
			bcomanda:0,
			tabs: [{chave: 'mesas', valor: 'MESAS'},{chave: 'comandas', valor: 'COMANDAS'}]
		}

		this.fetchData = this.fetchData.bind(this)
		this.aplicaFiltro = this.aplicaFiltro.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.toggleModalQrCode = this.toggleModalQrCode.bind(this)
		this.deleteItem = this.deleteItem.bind(this)
		this.habilitaItem = this.habilitaItem.bind(this)
		this.handleTipoMesa = this.handleTipoMesa.bind(this)
		this.setInicio = this.setInicio.bind(this)
		this.setQuantidade = this.setQuantidade.bind(this)
		this.createMesaComanda = this.createMesaComanda.bind(this)
	}

	async createMesaComanda(e){
		e.preventDefault()

		try {
			
			const inicio = this.state.inicio
			const total = parseInt(this.state.inicio) + parseInt(this.state.quantidade)

			for (let idx = inicio; idx < total; idx++) {
				const dt = {
					items:{
						status: 'aberta',
						token: Math.random().toString(16).slice(2),
						identificador: idx ,
						b_comanda: this.state.b_comanda},
					relations:{}
				}

				await saveObj(dt, this.state.model)

				getList(this.props.model, {filtro: this.state.filtro}).then(result => {
					this.setState({ lista: result })
				})
			}
			
			Helper.addNotification('success', 'Sucesso', 'Registro efetuado com sucesso', 'top-right')
		  } catch (e) {
			Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
		  }

		this.setState({ modalNew: false })
	}

	handleTipoMesa(e, comanda){
        this.setState({b_comanda:comanda})
	}
	
	setInicio(name, valor){
		this.setState({inicio:valor})
	}

	setQuantidade(name, valor){
		this.setState({quantidade:valor})
	}

	handleAddNew(e) {
		e.preventDefault()
		this.setState({modalNew: !this.state.modalNew})
	}

	toggleModalQrCode(e) {
		e.preventDefault()
		this.setState({modalQrCode: !this.state.modalQrCode, mesa: e.target.dataset.token, OBJID:e.target.dataset.mesa, bcomanda: e.target.dataset.bcomanda})
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getStructList(this.props.model).then(result => {
				this.setState({ struct: result })
			})

			getList(this.props.model, {filtro: 'mesas'}).then(result => {
				this.setState({ lista: result })
			})

			this.setState({model: this.props.model})
		}
	}

	async deleteItem(e){
		e.preventDefault()

		await deleteObj('Mesa/'+this.state.OBJID)
		this.setState({ modalAlert: false, OBJID: null })
		getList(this.props.model, {filtro: this.state.filtro}).then(result => {
			this.setState({ lista: result })
		})
	}

	aplicaFiltro(e){
		e.preventDefault()
		const t = e.target
		const id = t.dataset.filter != 'todos' ? t.dataset.filter : ''

		getList(this.state.model, {filtro: id}).then(result => {
			this.setState({ lista: result})
		})
	
		this.setState({filtro: id})
	}

	async habilitaItem(e, status){
		e.preventDefault()

		if (status == "fechada") {
			await saveObj({items:{id: e.target.dataset.mesa, status:"aberta"}}, "Mesa")
		} else {
			await saveObj({items:{id: e.target.dataset.mesa, status:"fechada"}}, "Mesa")
		}

		getList(this.props.model, {filtro: this.state.filtro}).then(result => {
			this.setState({ lista: result })
		})
	}

	componentDidMount() {
		this.fetchData()
	}

	
	getQrURL(){
		return "https://" + this.state.brand + ".grub.digital/dinein/"+this.state.mesa
	}
	
	downloadQR(){
		const name = 'qrcode_mesa_'+this.state.OBJID
		toImg('#qrcodeimage', name, {
			scale: 3,
			format: 'png',
			quality: 0.01,
			download: true
		  })
	}

	handlerPagination(e){
		getList(this.state.model, {filtro: this.state.filtro, page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}
	
	render() {
		const lista = this.state.lista
		return (
			<div>
				
				<div className="card border-0">
					<ul className="nav nav-tabs nav-tabs-v2 px-3">
						{this.state.tabs.map( (item) =>
							<li key={item.chave} className="nav-item me-2" >
								<Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
									{item.valor}
								</Link>
							</li>
						)}

						<li className="nav-item" style={{position: "absolute", right: "10px", top: "10px"}}>
							<Link to="/"  onClick={(e) => this.handleAddNew(e)}>
								<button type="button" className="btn btn-primary">
									+ Adicionar novo/a {this.props.model}
								</button> 
							</Link>
						</li>
					</ul>

					<div className="tab-content p-3">
						{lista.data && lista.data.length > 0 ? (
							<div className="tab-pane fade show active" id="allTab">
								
								<div className="table-responsive mb-3">
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<thead>
											<tr>
												<th></th>
												<th>Identificador</th>
												<th>Tipo</th>
												<th>QR Code</th>
												<th>Disponibilidade</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											{lista.data.length > 0 && lista.data.map((item) => 
												<tr key={"item_"+item.id}>
													<td className="w-10px align-middle">
														<div className="form-check">
															<input type="checkbox" className="form-check-input" id="product1" />
															<label className="form-check-label" for="product1"></label>
														</div>
													</td>
													<td>{item.identificador}</td>
													<td>
														{item.b_comanda ? (
															<span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda 
															</span>
														) : (
															<span className="badge border border-pink text-pink px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa fa-utensils fs-9px fa-fw me-5px"></i> Mesa
															</span>
														)}
													</td>
													<td>
														<button className="btn btn-white" style={{color:"#0043ff"}} data-token={item.token} data-mesa={item.identificador} data-bcomanda={item.b_comanda} onClick={(e) => this.toggleModalQrCode(e)}>
															<i className="fa-solid fa-qrcode"></i> Ver QR code
														</button>
													</td>
													
													<td>
														<div className="form-check form-switch" style={item.status === 'ocupada' ? {filter:'grayscale(1)'} : {}}>	
															<input className="form-check-input form-check-input-pos" data-mesa={item.id} id={item.id} onClick={(e) => this.habilitaItem(e, item.status)} checked={item.status !== 'fechada' ? true : false} disabled={item.status === 'ocupada' ? true : false} type="checkbox" />
															<label className="form-check-label" htmlFor={item.id}>{item.status === 'fechada' ? "Desabilitada" : "Habilitada" }</label>
														</div>
													</td>
													<td>
													{item.status === 'ocupada' ?
														<button className="btn btn-white" data-mesa={item.id} onClick={(e) => {e.preventDefault(); this.setState({ modalOcupada: true, OBJID: e.target.dataset.mesa })}}>
															<i className="fa fa-trash fs-9px fa-fw me-5px"></i> Excluir
														</button>
													:
														<button className="btn btn-white" data-mesa={item.id} onClick={(e) => {e.preventDefault(); this.setState({ modalAlert: true, OBJID: e.target.dataset.mesa })}}>
															<i className="fa fa-trash fs-9px fa-fw me-5px"></i> Excluir
														</button>
													}
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								{this.props.paginacao && (this.paginacao())}
							</div>
						)
						:
						( 
							<div className="card border-0" style={{padding:"15px"}}>
								
								<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
								<h1 style={{textAlign:"center"}}>Nenhuma mesa cadastrada por aqui! Cadastre uma mesa agora. </h1>
								<p  style={{textAlign:"center"}}></p>
							</div>
						)}
					</div>
				</div>

				<Modal isOpen={this.state.modalQrCode}>
					<ModalHeader>QR CODE DA {this.state.bcomanda == 1 ? "COMANDA" : "MESA"}:<b>{this.state.OBJID}</b></ModalHeader>
					<ModalBody style={{textAlign:"center"}}>
						<QRCode
							size={150}
							style={{ margin: "auto", height: "auto", maxWidth: "100%", width: "300px" }}
							value={this.getQrURL()}
							viewBox={`0 0 150 150`}
							id="qrcodeimage"
						/>
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-primary" data-mesa={null} onClick={(e) => this.downloadQR(e)}>BAIXAR QR CODE</button>
						<button className="btn btn-white" data-mesa={null} onClick={(e) => this.toggleModalQrCode(e)}>CANCELAR</button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.modalAlert} toggle={() => this.setState({ modalAlert: false })}>
					<ModalBody>
						<div className='text-center'>
							<Lottie animationData={lottieAlerta} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Atenção: esta ação é crítica e irreversível.</h3>
								<p>Apagar uma mesa ou comanda pode acarretar em grandes transtornos. Após excluir, o QR code impresso deixará de funcionar.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalAlert: false })}>Cancelar</button>
							<button className="btn btn-danger" onClick={(e) => this.deleteItem(e)}>Sim, desejo excluir este item</button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={this.state.modalOcupada} toggle={() => this.setState({ modalOcupada: false })}>
					<ModalBody>
						<div className='text-center'>
							<Lottie animationData={lottieAlerta} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Ação crítica não permitida</h3>
								<p>Esta mesa/comanda esta ocupada em este momento e não pode ser excluida, para excluir precisa estar sem produtos ou pedidos asociados.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalOcupada: false })}>Ok</button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={this.state.modalNew} toggle={() => this.setState({ modalNew: false })}>
					<ModalBody>
						<div className="p-4">
							<h3>Criar nova mesa ou comanda</h3>
							
							<p>Aqui você pode criar as mesas do seu estabelecimento, as quais irão gerar, além da mesa física ou comanda (cartão) se criará, um QR Code para que o cliente possa realizar seus pedidos ou apenas visualizar o cardápio.</p>
							
							<div className="row">
								<div className='mb-5 col-md-8'>
									<span className="form-check col-md-6" style={{display:"inline-block"}}>
										<input className="form-check-input" type="radio" checked={this.state.b_comanda === 0}  onChange={(e) => this.handleTipoMesa(e, 0)} name="b_comanda"  />
										<label className="form-check-label" htmlF>
											Mesa
										</label>
									</span>

									<span className="form-check col-md-6" style={{display:"inline-block"}}>
										<input className="form-check-input" type="radio" checked={this.state.b_comanda === 1}  onChange={(e) => this.handleTipoMesa(e, 1)} name="b_comanda"  />
										<label className="form-check-label" htmlFor="flexRadioDefault1">
											Comanda
										</label>
									</span>
								</div>
							</div>
							<div className="row">
								<div className="form-floating col-md-6 mb-15px" >
									<InputText
										nome="quantidade"
										setFomrData={this.setQuantidade}
									/>
									<Label nome="quantidade" label="Quantidade de comandas"/>
								</div>

								<div className="form-floating col-md-6 mb-15px" >
									<InputText
										nome="inicio"
										setFomrData={this.setInicio}
									/>
									<Label nome="inicio" label="Número de início"/>
								</div>
							</div>

							<button className="btn btn-primary col-md-3 offset-md-9" style={{marginRight: '15px'}} onClick={(e) => this.createMesaComanda(e)}>Cadastrar</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

export default ListaMesas;